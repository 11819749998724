import React from 'react';
import Button from '../Button';
import Header from '../Header';
import { Container } from '../Layout';
import { rocketChatIcon } from '../../utilities/icons';
import * as styles from './DisclaimerSection.module.scss';

const DisclaimerSection = () => {
    return (
        <div className={styles.disclaimerSection}>
            <Container>
                <div className={styles.disclaimerMain}>
                    <div className={styles.contact}>
                        <Header type="h2" text="Can't find the random generator or name that you're looking for?" />
                        <Header type="h3" text="Let me know!" />
                        <Button icon={rocketChatIcon} text="I love to hear new and amazing ideas" type="Link" href="/contact/" />
                    </div>
                    <div className={styles.disclaimer}>
                        <Header type="h2" text="Disclaimer" />
                        <p>Hi! Thank you for using Generator Ninja! I run this site by myself and it's been a lot of work and a lot of fun. This site makes money through advertising, Amazon affiliate links, and donations in order to help me keep it operational.</p>
                        <Header type="h3" text="Names and Other Fictional Works" />
                        <p>Most of the names created through the generators are completely unique, and you are free to use them in your own stuff. Some of the names or subjects might be sourced from video games, movies, tv, or other sources and are the respective property of those works.</p>
                        <p>I hope you enjoy and feel free to contact me if you have any questions!</p>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default DisclaimerSection;
