import React from 'react';
import * as styles from './Button.module.scss';
import Icon from '../Icon';
import { Link } from 'gatsby';

type IButtonProps =
    { text: string,
    icon: string,
    light?: Boolean} & (
        | { type: "Function", handleClick: Function}
        | { type: "Link", href: string }
    )

const Button = (props: IButtonProps) => {
    if(props.type === "Function") return <button className={`${styles.btn} ${props.light ? styles.light : styles.dark}`} type="button" onClick={() => props.handleClick()}><Icon icon={props.icon} style={`${styles.btnIcon} ${props.light ? styles.lightIcon : styles.darkIcon}`} /><span className={styles.btnText}>{props.text}</span></button>
    return <Link className={styles.btnLink} to={props.href}><button className={`${styles.btn} ${props.light ? styles.light : styles.dark}`} type="button"><Icon icon={props.icon} style={`${styles.btnIcon} ${props.light ? styles.lightIcon : styles.darkIcon}`} /><span className={styles.btnText}>{props.text}</span></button></Link>
}

export default Button
