import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import Layout, { Container, FullWidthContainer } from '../components/Layout';
import Header from '../components/Header';
import Button from '../components/Button';
import * as styles from './Home.module.scss';
import { randNumberRange } from '../utilities/random';
import { generateIcon } from '../utilities/icons';
import PopularSection from '../components/PopularSection';
import CategorySection from '../components/CategorySection';
import DisclaimerSection from '../components/DisclaimerSection';
import Footer from '../components/Footer';
import Meta from '../components/Meta';

const Home = ({ data }) => {
  const [index, setIndex] = useState(0);

  let pages = [];
  data.allMongodbGeneratorninjaFixedattrs.nodes.forEach((node) => {
      pages.push(node);
  });
  data.allMongodbGeneratorninjaRandomattrs.nodes.forEach((node) => {
      pages.push(node);
  });
  data.allMongodbGeneratorninjaNocards.nodes.forEach((node) => {
      pages.push(node);
  });

  const generateGenerator = () => {
    const rand = randNumberRange(0, pages.length);
    if (rand !== index) return setIndex(rand);
    generateGenerator();
  }

  // eslint-disable-next-line
  useEffect(() => generateGenerator(), []);

  return (
    <Layout>
      <Meta title="Random Generators" description="Random generators for every occasion. Fantasy, sci-fi, animals, geography, words, names, and many more random generators to generate random fun!" />
      <FullWidthContainer>
        <Container>
          <Header type="h1" text="Generating random generators to generate random fun!" />
          <div className={styles.btnRow}>
            <Button text={pages[index].title} light={false} type="Link" href={pages[index].slug} icon={pages[index].icon} />
            <Button text="Generate Another Generator" light={true} type="Function" handleClick={generateGenerator} icon={generateIcon} />
          </div>
        </Container>
      </FullWidthContainer>
      <PopularSection />
      <CategorySection />
      <DisclaimerSection />
      <Footer />
    </Layout>
  )
}

export default Home;

export const query = graphql`
  query ActivePages {
    allMongodbGeneratorninjaFixedattrs(filter: {active: {eq: true}}) {
      nodes {
        name
        category
        title
        icon
        popular
        slug
      }
    }
    allMongodbGeneratorninjaNocards(filter: {active: {eq: true}}) {
      nodes {
        name
        category
        title
        icon
        popular
        slug
      }
    }
    allMongodbGeneratorninjaRandomattrs(filter: {active: {eq: true}}) {
      nodes {
        category
        name
        title
        icon
        popular
        slug
      }
    }
  }
`

